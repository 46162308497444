import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthForm } from "../../components/AuthForm";
import { Button, Divider, Flex, FlexSpan, Icon, PasswordInput, TextInput } from "../../ui";
import { STORAGE_TOKEN } from "../../constants/common";
import { VALID_EMAIL_REGEX } from "../../constants/regex";
import { PersonalPageUrl, SignUpPageUrl } from "../../constants/routes";
import { useUser } from "../../hooks";
import { api } from "../../api";
import googleLogo from "../../assets/images/google.svg";
export const LoginPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [, setUser] = useUser();
    const storageToken = window.localStorage.getItem(STORAGE_TOKEN);
    const isFirstRender = useRef(true);
    const onSubmit = useCallback(() => {
        if (emailError || passwordError) {
            return;
        }
        if (!email || !password) {
            if (!email) {
                setEmailError("Email not specified");
            }
            if (!password) {
                setPasswordError("Password not specified");
            }
            return;
        }
        if (email.length > 0 && !email.match(VALID_EMAIL_REGEX)) {
            setEmailError("Invalid email format");
            return;
        }
        isFirstRender.current = false;
        api.auth
            .createLogin({
            email,
            password,
        })
            .then(response => {
            setUser(response.user);
            window.localStorage.setItem(STORAGE_TOKEN, response.access_token.token);
        })
            .finally(() => {
            navigate(PersonalPageUrl.path);
        });
    }, [email, emailError, navigate, password, passwordError, setUser]);
    useEffect(() => {
        if (storageToken && isFirstRender.current) {
            navigate(PersonalPageUrl.path);
        }
    }, [storageToken]); // eslint-disable-line
    return (_jsx(AuthForm, { title: "Welcome back to OneCity!", subtitle: "Create strategies and analyse city sites", children: _jsxs(Flex, { column: true, gap: "0.5rem", width: "100%", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(Button, { secondary: true, width: "100%", height: "2.75rem", onClick: () => (window.location.href = `${process.env.API_URL}/auth/google`), children: _jsxs(Flex, { width: "auto", alignItems: "center", gap: "0.25rem", children: [_jsx(FlexSpan, { children: _jsx("img", { src: googleLogo, alt: "", style: { position: "relative", top: "0.0625rem" } }) }), _jsx(FlexSpan, { children: "Log In with Google" })] }) }) }), _jsxs(FlexSpan, { width: "100%", m: "1.5rem 0", children: [_jsx(Divider, {}), _jsx(FlexSpan, { position: "absolute", backgroundColor: "#ffffff", width: "3rem", textAlign: "center", color: "#8275AD", top: "-0.625rem", left: "calc(50% - 1.5rem)", children: "OR" })] }), _jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { column: true, gap: "0.125rem", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(TextInput, { placeholder: "Email", value: email, onChange: value => {
                                        setEmailError("");
                                        setEmail(value);
                                    }, onEnter: onSubmit, onBlur: () => {
                                        if (email.length > 0 && !email.match(VALID_EMAIL_REGEX)) {
                                            setEmailError("Invalid email format");
                                        }
                                    }, error: Boolean(emailError) }) }), emailError && (_jsx(FlexSpan, { fontWeight: 500, fontSize: "12px", lineHeight: "16px", color: "#ED627F", children: emailError }))] }) }), _jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { column: true, gap: "0.125rem", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(PasswordInput, { placeholder: "Password", value: password, onChange: value => {
                                        setPasswordError("");
                                        setPassword(value);
                                    }, onEnter: onSubmit, error: Boolean(passwordError), isPasswordVisible: isPasswordVisible, suffix: password && (_jsx(FlexSpan, { display: "flex", style: { cursor: "pointer" }, onClick: () => setIsPasswordVisible(!isPasswordVisible), children: _jsx(Icon, { icon: isPasswordVisible ? "eye-closed" : "eye", color: "#8275AD", hoverColor: "#5732d3" }) })) }) }), passwordError && (_jsx(FlexSpan, { fontWeight: 500, fontSize: "12px", lineHeight: "16px", color: "#ED627F", children: passwordError }))] }) }), _jsx(FlexSpan, { mt: "2rem", children: _jsx(Button, { width: "100%", height: "2.75rem", onClick: onSubmit, children: "Log In" }) }), _jsx(FlexSpan, { mt: "2rem", width: "100%", textAlign: "center", fontWeight: 500, fontSize: "16px", lineHeight: "20px", children: _jsx(Link, { to: SignUpPageUrl.path, children: "Create account" }) })] }) }));
};
