import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthForm } from "../../components/AuthForm";
import { Button, Checkbox, Flex, FlexSpan, Icon, PasswordInput, Select, TextInput } from "../../ui";
import { STORAGE_TOKEN } from "../../constants/common";
import { VALID_EMAIL_REGEX } from "../../constants/regex";
import { LoginPageUrl, MainPageUrl } from "../../constants/routes";
import { useIndustries } from "../../hooks";
import { api } from "../../api";
// eslint-disable-next-line max-lines-per-function
export const SignUpPage = () => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [industry, setIndustry] = useState();
    const [accepted, setAccepted] = useState(false);
    const [nameError, setNameError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [industryError, setIndustryError] = useState("");
    const [acceptedError, setAcceptedError] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isPasswordConfirmVisible, setIsPasswordConfirmVisible] = useState(false);
    const storageToken = window.localStorage.getItem(STORAGE_TOKEN);
    const isFirstRender = useRef(true);
    const [industries] = useIndustries();
    const [success, setSuccess] = useState(false);
    const disabled = useMemo(() => !name ||
        name.length < 2 ||
        !email ||
        !password ||
        !confirmPassword ||
        !industry ||
        !accepted ||
        Boolean(nameError) ||
        Boolean(emailError) ||
        Boolean(passwordError) ||
        Boolean(confirmPasswordError) ||
        Boolean(industryError) ||
        acceptedError, [
        accepted,
        confirmPassword,
        confirmPasswordError,
        email,
        emailError,
        industry,
        name,
        nameError,
        password,
        passwordError,
        industryError,
        acceptedError,
    ]);
    const onSubmit = useCallback(() => {
        if (!name) {
            setNameError("Name cannot be empty");
        }
        if (!email) {
            setEmailError("Email cannot be empty");
        }
        if (!password) {
            setPasswordError("Password cannot be empty");
        }
        if (password && !confirmPassword) {
            setConfirmPasswordError("Passwords do not match");
        }
        if (!industry) {
            setIndustryError("Industry cannot be empty");
        }
        if (!accepted) {
            setAcceptedError(true);
        }
        if (disabled) {
            return;
        }
        isFirstRender.current = false;
        api.auth
            .createSignUp({
            name,
            email,
            password,
            industry_id: industry,
        })
            .then(() => {
            setSuccess(true);
        });
    }, [accepted, disabled, email, industry, name, password]);
    useEffect(() => {
        if (storageToken && isFirstRender.current) {
            navigate(MainPageUrl.path);
        }
    }, [storageToken]); // eslint-disable-line
    if (success) {
        return (_jsx(AuthForm, { icon: "done", title: "Account successfully created", subtitle: "We are glad that you joined us!", children: _jsx(Button, { width: "100%", height: "2.75rem", onClick: () => navigate(LoginPageUrl.path), children: "Log In" }) }));
    }
    return (_jsx(AuthForm, { title: "Create account", subtitle: "Create strategies and analyze city sites", children: _jsxs(Flex, { column: true, gap: "0.5rem", width: "100%", children: [_jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { column: true, gap: "0.125rem", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(TextInput, { autoComplete: "off", placeholder: "Your full name", value: name, onChange: value => {
                                        setNameError("");
                                        setName(value);
                                    }, onEnter: onSubmit, onBlur: () => name.length > 0 && name.length < 2 && setNameError("Two characters minimum"), error: Boolean(nameError) }) }), nameError && (_jsx(FlexSpan, { fontWeight: 500, fontSize: "12px", lineHeight: "16px", color: "#ED627F", children: nameError }))] }) }), _jsx(FlexSpan, { width: "100%", children: _jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { column: true, gap: "0.125rem", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(TextInput, { autoComplete: "off", placeholder: "Email", value: email, onChange: value => {
                                            setEmailError("");
                                            setEmail(value);
                                        }, onEnter: onSubmit, onBlur: () => {
                                            email.length > 0 && !email.match(VALID_EMAIL_REGEX) && setEmailError("Invalid email format");
                                        }, error: Boolean(emailError) }) }), emailError && (_jsx(FlexSpan, { fontWeight: 500, fontSize: "12px", lineHeight: "16px", color: "#ED627F", children: emailError }))] }) }) }), _jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { column: true, gap: "0.125rem", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(PasswordInput, { autoComplete: "off", placeholder: "Password", value: password, onChange: value => {
                                        setPasswordError("");
                                        setConfirmPasswordError("");
                                        setPassword(value);
                                    }, onEnter: onSubmit, onBlur: () => {
                                        password.length > 0 && password.length < 6 && setPasswordError("Six characters minimum");
                                        password.length > 0 &&
                                            confirmPassword.length > 0 &&
                                            confirmPassword !== password &&
                                            setConfirmPasswordError("Passwords do not match");
                                    }, error: Boolean(passwordError), isPasswordVisible: isPasswordVisible, suffix: password && (_jsx(FlexSpan, { display: "flex", style: { cursor: "pointer" }, onClick: () => setIsPasswordVisible(!isPasswordVisible), children: _jsx(Icon, { icon: isPasswordVisible ? "eye-closed" : "eye", color: "#8275AD", hoverColor: "#5732d3" }) })) }) }), passwordError && (_jsx(FlexSpan, { fontWeight: 500, fontSize: "12px", lineHeight: "16px", color: "#ED627F", children: passwordError }))] }) }), _jsx(FlexSpan, { width: "100%", children: _jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { column: true, gap: "0.125rem", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(PasswordInput, { autoComplete: "off", placeholder: "Confirm password", value: confirmPassword, onChange: value => {
                                            setConfirmPasswordError("");
                                            setConfirmPassword(value);
                                        }, onEnter: onSubmit, onBlur: () => password.length > 0 &&
                                            confirmPassword.length > 0 &&
                                            confirmPassword !== password &&
                                            setConfirmPasswordError("Passwords do not match"), error: Boolean(confirmPasswordError), isPasswordVisible: isPasswordConfirmVisible, suffix: confirmPassword && (_jsx(FlexSpan, { display: "flex", style: { cursor: "pointer" }, onClick: () => setIsPasswordConfirmVisible(!isPasswordConfirmVisible), children: _jsx(Icon, { icon: isPasswordConfirmVisible ? "eye-closed" : "eye", color: "#8275AD", hoverColor: "#5732d3" }) })) }) }), confirmPasswordError && (_jsx(FlexSpan, { fontWeight: 500, fontSize: "12px", lineHeight: "16px", color: "#ED627F", children: confirmPasswordError }))] }) }) }), _jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { column: true, gap: "0.125rem", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(Select, { placeholder: "Industry/Sector", options: industries?.map(item => ({ value: item.id, label: item.title })) ?? [], onChange: option => {
                                        setIndustryError("");
                                        setIndustry(option.value);
                                    }, error: Boolean(industryError) }) }), industryError && (_jsx(FlexSpan, { fontWeight: 500, fontSize: "12px", lineHeight: "16px", color: "#ED627F", children: industryError }))] }) }), _jsx(FlexSpan, { width: "100%", mt: "1rem", children: _jsx(Checkbox, { label: _jsxs(_Fragment, { children: ["I agree and accept", " ", _jsx("a", { target: "_blank", onClick: e => e.stopPropagation(), href: "https://onecity.dev/terms", rel: "noreferrer", children: "Terms and Conditions" })] }), checked: accepted, onChange: () => {
                            setAcceptedError(false);
                            setAccepted(!accepted);
                        }, error: acceptedError }) }), _jsx(FlexSpan, { mt: "2rem", children: _jsx(Button, { width: "100%", height: "2.75rem", onClick: onSubmit, children: "Sign Up" }) }), _jsxs(FlexSpan, { mt: "2rem", width: "100%", textAlign: "center", fontWeight: 500, fontSize: "16px", lineHeight: "20px", color: "#8275AD", children: ["Already have an account? ", _jsx(Link, { to: LoginPageUrl.path, children: "Log In" })] })] }) }));
};
