import styled from "styled-components";
export const TextareaInput = styled.textarea `
  box-sizing: border-box;
  background: ${({ error }) => (error ? "#ffcae2" : "#f6f5f8")};
  border: none;
  border-radius: 0.75rem;
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height};
  outline: none;
  color: ${({ error }) => (error ? "#ed627f" : "#282828")};
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 12px;
  resize: none;

  &::placeholder {
    color: ${({ error }) => (error ? "#ed627f" : "#7b7997")};
  }
`;
