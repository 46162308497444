import styled, { css } from "styled-components";
const hideArrowsMixin = css `
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export const InputContainer = styled.div `
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: ${({ error }) => (error ? "#ffcae2" : "#f6f5f8")};
  border-radius: 0.75rem;
  width: ${({ width }) => width || "100%"};
  ${({ hideArrows }) => hideArrows && hideArrowsMixin}
`;
export const InputField = styled.input `
  width: 100%;
  border: none;
  outline: none;
  color: ${({ error }) => (error ? "#ed627f" : "#282828")};
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.75rem;
  background-color: transparent;

  &::placeholder {
    color: ${({ error }) => (error ? "#ed627f" : "#7b7997")};
  }
`;
export const InputPrefix = styled.div `
  display: flex;
  padding: 0 0 0 1rem;
`;
export const InputSuffix = styled.div `
  display: flex;
  padding: 0 1rem 0 0;
`;
