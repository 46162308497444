import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { InjectGlobalStyles } from "../components/InjectGlobalStyles";
import { LoginPage } from "./LoginPage";
import { SignUpPage } from "./SignUpPage";
import { MainPage } from "./MainPage";
import { PersonalPage } from "./PersonalPage";
import { OrdersPage } from "./OrdersPage";
import { Dialogs } from "../components/Dialogs";
import { STORAGE_TOKEN } from "../constants/common";
import { LoginPageUrl, MainPageUrl, OrdersPageUrl, PersonalPageUrl, SignUpPageUrl } from "../constants/routes";
import { useAppHeight, useIndustries, useToken, useUser } from "../hooks";
import { api } from "../api";
export const Pages = () => {
    const [searchParams] = useSearchParams();
    const queryToken = useMemo(() => searchParams.get("token"), [searchParams]);
    const storageToken = window.localStorage.getItem(STORAGE_TOKEN);
    const [user, setUser] = useUser();
    const [token, setToken] = useToken();
    const [industries, setIndustries] = useIndustries();
    useEffect(() => {
        if (queryToken) {
            window.localStorage.setItem(STORAGE_TOKEN, queryToken);
            setToken(queryToken);
            api.users
                .getMe()
                .then(response => setUser(response))
                .finally(() => (window.location.href = window.location.href.split("?")[0]));
        }
        else if (!storageToken) {
            setUser(null);
            setToken(undefined);
        }
        else if (!token) {
            setToken(storageToken);
            if (!user) {
                api.users.getMe().then(setUser);
            }
        }
    }, [storageToken]); // eslint-disable-line
    useAppHeight();
    useEffect(() => {
        if (industries === undefined) {
            api.industries.getIndustries().then(setIndustries);
        }
    }, []); // eslint-disable-line
    return (_jsxs(InjectGlobalStyles, { children: [_jsx(Dialogs, {}), _jsxs(Routes, { children: [_jsx(Route, { path: LoginPageUrl.path, element: _jsx(LoginPage, {}) }), _jsx(Route, { path: SignUpPageUrl.path, element: _jsx(SignUpPage, {}) }), _jsx(Route, { path: MainPageUrl.path, element: _jsx(MainPage, {}) }), _jsx(Route, { path: PersonalPageUrl.path, element: _jsx(PersonalPage, {}) }), _jsx(Route, { path: OrdersPageUrl.path, element: _jsx(OrdersPage, {}) })] })] }));
};
