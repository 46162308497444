import { jsx as _jsx } from "react/jsx-runtime";
import ReactSelect from "react-select";
export const setWidth = (width) => typeof width === "number" ? `${width}px` : typeof width === "undefined" ? "100%" : width;
export const Select = ({ placeholder, noOptionsMessage, styles, onChange, disabled, width, hideDropdownIndicator, isSearchable = false, value, error, ...rest }) => {
    return (_jsx(ReactSelect, { isDisabled: disabled, placeholder: typeof placeholder === "string" ? placeholder : "Select...", noOptionsMessage: () => "Empty", isSearchable: isSearchable, menuPortalTarget: document.body, onChange: (changeValue) => changeValue && onChange && onChange(changeValue), styles: {
            ...styles,
            control: (base) => ({
                ...base,
                background: error ? "#ffcae2" : "#f6f5f8",
                borderRadius: "0.75rem",
                borderColor: "transparent",
                boxShadow: "none",
                ":hover": {
                    borderColor: "transparent",
                },
                ":active": {
                    borderColor: "transparent",
                },
            }),
            container: (base) => ({
                ...base,
                width: setWidth(width),
                border: 0,
            }),
            placeholder: (base) => ({
                ...base,
                color: error ? "#ed627f" : "#7b7997",
                fontWeight: 500,
                fontSize: "1rem",
                lineHeight: "1.25rem",
                letterSpacing: "-0.011em",
            }),
            valueContainer: (base) => ({
                ...base,
                padding: "0.75rem",
            }),
            input: (base) => ({
                ...base,
                outline: "none",
            }),
            singleValue: (base) => ({
                ...base,
                fontWeight: 500,
                fontSize: "1rem",
                lineHeight: "1.25rem",
                letterSpacing: "-0.011em",
                color: error ? "#ed627f" : "#282828",
            }),
            indicatorSeparator: () => ({
                display: "none",
            }),
            dropdownIndicator: (base) => ({
                ...base,
                color: error ? "#ed627f" : value ? "#5732d3" : "#7b7997",
            }),
            option: (base, { isFocused, isSelected }) => ({
                ...base,
                backgroundColor: !isSelected && !isFocused ? "#f6f5f8" : "#dde0ff",
                color: !isSelected && !isFocused ? "#7b7997" : "#282828",
                fontWeight: 500,
                fontSize: "1rem",
                lineHeight: "1.25rem",
                letterSpacing: "-0.011em",
                borderRadius: "0.75rem",
                padding: "0.75rem",
                marginBottom: "0.125rem",
                ":active": {
                    backgroundColor: "#dde0ff",
                },
                ":last-child": {
                    marginBottom: 0,
                },
            }),
            menu: (base) => ({
                ...base,
                background: "#ffffff",
                boxShadow: "0 0 0.75rem rgba(0, 0, 0, 0.24)",
                borderRadius: "0.75rem",
            }),
            menuList: (base) => ({
                ...base,
                borderRadius: "0.75rem",
                padding: "0.25rem",
            }),
            menuPortal: (base) => ({
                ...base,
                zIndex: 1001,
            }),
        }, ...rest }));
};
