import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from "react";
import { Button, Flex, FlexSpan, Icon, Modal, PasswordInput } from "../../../ui";
import { DIALOGS } from "../../../constants/dialogs";
import { useDialog, useUser } from "../../../hooks";
import { api } from "../../../api";
export const ChangePasswordDialog = () => {
    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [currentPasswordError, setCurrentPasswordError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isPasswordConfirmVisible, setIsPasswordConfirmVisible] = useState(false);
    const openDialog = useDialog();
    const [user] = useUser();
    const disabled = useMemo(() => !currentPassword ||
        !password ||
        !confirmPassword ||
        Boolean(currentPasswordError) ||
        Boolean(passwordError) ||
        Boolean(confirmPasswordError), [currentPassword, password, confirmPassword, currentPasswordError, passwordError, confirmPasswordError]);
    const onSave = useCallback(() => {
        if (!currentPassword) {
            setCurrentPasswordError("Current password cannot be empty");
        }
        if (!password) {
            setPasswordError("Password cannot be empty");
        }
        if (password && !confirmPassword) {
            setConfirmPasswordError("Passwords do not match");
        }
        if (disabled) {
            return;
        }
        api.users.updatePassword({ current_password: currentPassword, new_password: password }).then(response => {
            if (response.success) {
                openDialog(DIALOGS.ACCOUNT_DIALOG);
            }
            else {
                // TODO: implement different fields error messages on the server side
            }
        });
    }, [disabled, currentPassword, password, openDialog]);
    if (!user) {
        return null;
    }
    return (_jsx(Modal, { content: _jsxs(Flex, { column: true, gap: "2rem", children: [_jsx(FlexSpan, { fontWeight: 500, fontSize: "24px", lineHeight: "29px", children: "Change password" }), _jsx(FlexSpan, { children: _jsxs(Flex, { column: true, gap: "0.5rem", children: [_jsx(FlexSpan, { width: "100%", mb: "1rem", children: _jsxs(Flex, { column: true, gap: "0.125rem", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(PasswordInput, { autoComplete: "off", placeholder: "Current password", value: currentPassword, onChange: value => {
                                                    setCurrentPasswordError("");
                                                    setCurrentPassword(value);
                                                }, onBlur: () => {
                                                    api.users.checkPassword({ password: currentPassword }).then(response => {
                                                        if (!response.success) {
                                                            setCurrentPasswordError(response.message);
                                                        }
                                                    });
                                                }, error: Boolean(currentPasswordError), isPasswordVisible: isCurrentPasswordVisible, suffix: currentPassword && (_jsx(FlexSpan, { display: "flex", style: { cursor: "pointer" }, onClick: () => setIsCurrentPasswordVisible(!isCurrentPasswordVisible), children: _jsx(Icon, { icon: isCurrentPasswordVisible ? "eye-closed" : "eye", color: "#8275AD", hoverColor: "#5732d3" }) })) }) }), currentPasswordError && (_jsx(FlexSpan, { fontWeight: 500, fontSize: "12px", lineHeight: "16px", color: "#ED627F", children: currentPasswordError }))] }) }), _jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { column: true, gap: "0.125rem", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(PasswordInput, { autoComplete: "off", placeholder: "New password", value: password, onChange: value => {
                                                    setPasswordError("");
                                                    setConfirmPasswordError("");
                                                    setPassword(value);
                                                }, onBlur: () => {
                                                    password.length > 0 && password.length < 6 && setPasswordError("Six characters minimum");
                                                    password.length > 0 &&
                                                        confirmPassword.length > 0 &&
                                                        confirmPassword !== password &&
                                                        setConfirmPasswordError("Passwords do not match");
                                                }, error: Boolean(passwordError), isPasswordVisible: isPasswordVisible, suffix: password && (_jsx(FlexSpan, { display: "flex", style: { cursor: "pointer" }, onClick: () => setIsPasswordVisible(!isPasswordVisible), children: _jsx(Icon, { icon: isPasswordVisible ? "eye-closed" : "eye", color: "#8275AD", hoverColor: "#5732d3" }) })) }) }), passwordError && (_jsx(FlexSpan, { fontWeight: 500, fontSize: "12px", lineHeight: "16px", color: "#ED627F", children: passwordError }))] }) }), _jsx(FlexSpan, { width: "100%", children: _jsx(FlexSpan, { width: "100%", children: _jsxs(Flex, { column: true, gap: "0.125rem", children: [_jsx(FlexSpan, { width: "100%", children: _jsx(PasswordInput, { autoComplete: "off", placeholder: "Confirm new password", value: confirmPassword, onChange: value => {
                                                        setConfirmPasswordError("");
                                                        setConfirmPassword(value);
                                                    }, onBlur: () => password.length > 0 &&
                                                        confirmPassword.length > 0 &&
                                                        confirmPassword !== password &&
                                                        setConfirmPasswordError("Passwords do not match"), error: Boolean(confirmPasswordError), isPasswordVisible: isPasswordConfirmVisible, suffix: confirmPassword && (_jsx(FlexSpan, { display: "flex", style: { cursor: "pointer" }, onClick: () => setIsPasswordConfirmVisible(!isPasswordConfirmVisible), children: _jsx(Icon, { icon: isPasswordConfirmVisible ? "eye-closed" : "eye", color: "#8275AD", hoverColor: "#5732d3" }) })) }) }), confirmPasswordError && (_jsx(FlexSpan, { fontWeight: 500, fontSize: "12px", lineHeight: "16px", color: "#ED627F", children: confirmPasswordError }))] }) }) })] }) })] }), footer: _jsxs(Flex, { width: "auto", gap: "0.75rem", children: [_jsx(FlexSpan, { children: _jsx(Button, { secondary: true, height: "2.5rem", pl: "2rem", pr: "2rem", onClick: () => openDialog(DIALOGS.ACCOUNT_DIALOG), children: "Cancel" }) }), _jsx(FlexSpan, { children: _jsx(Button, { height: "2.5rem", pl: "2rem", pr: "2rem", onClick: onSave, children: "Confirm" }) })] }), onClose: () => openDialog(DIALOGS.ACCOUNT_DIALOG) }));
};
