import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import isNil from "lodash/isNil";
import { InputLabel, OrdersFiltersContainer, OrdersTableContainer, OrdersWrapper } from "./styled";
import { Flex, FlexSpan, Select, TextInput } from "../../ui";
import { EMPTY_VALUE } from "../../constants/common";
import { useIndustries } from "../../hooks";
import { formatDate } from "../../utils/format";
import { api } from "../../api";
import { OrdersCompletedSelectOptions } from "./types";
export const OrdersPage = () => {
    const [orders, setOrders] = useState([]);
    const [industries] = useIndustries();
    const [search, setSearch] = useState("");
    const [selectedIndustry, setSelectedIndustry] = useState();
    const [selectedCompleted, setSelectedCompleted] = useState(OrdersCompletedSelectOptions.All);
    const industriesOptions = useMemo(() => [
        { value: undefined, label: "All" },
        ...(industries?.map(industry => ({
            value: industry.id,
            label: industry.title,
        })) ?? []),
    ], [industries]);
    const completedOptions = useMemo(() => [
        { value: OrdersCompletedSelectOptions.All, label: "All" },
        { value: OrdersCompletedSelectOptions.CompletedOnly, label: "Completed only" },
        { value: OrdersCompletedSelectOptions.IncompleteOnly, label: "Incomplete only" },
    ], []);
    const filteredOrders = useMemo(() => {
        return orders
            .filter(order => search
            ? order.user.name.toLowerCase().includes(search.toLowerCase()) ||
                order.user.email.toLowerCase().includes(search.toLowerCase())
            : true)
            .filter(order => (selectedIndustry !== undefined ? order.user.industry_id === selectedIndustry : true))
            .filter(order => {
            switch (selectedCompleted) {
                case OrdersCompletedSelectOptions.CompletedOnly:
                    return !isNil(order.completed_at);
                case OrdersCompletedSelectOptions.IncompleteOnly:
                    return isNil(order.completed_at);
                default:
                    return true;
            }
        });
    }, [orders, search, selectedCompleted, selectedIndustry]);
    useEffect(() => {
        api.orders.getStatistics().then(response => setOrders(response.reverse()));
    }, []);
    return (_jsxs(OrdersWrapper, { children: [_jsx(OrdersFiltersContainer, { children: _jsxs(Flex, { alignItems: "center", gap: "2rem", children: [_jsx(FlexSpan, { children: _jsxs(Flex, { column: true, gap: "0.5rem", children: [_jsx(FlexSpan, { children: _jsx(InputLabel, { children: "Search" }) }), _jsx(FlexSpan, { children: _jsx(TextInput, { placeholder: "Enter name or email...", width: "200px", value: search, onChange: setSearch }) })] }) }), _jsx(FlexSpan, { children: _jsxs(Flex, { column: true, gap: "0.5rem", children: [_jsx(FlexSpan, { children: _jsx(InputLabel, { children: "Industry" }) }), _jsx(FlexSpan, { children: _jsx(Select, { width: "330px", options: industriesOptions, defaultValue: industriesOptions[0], value: industriesOptions.find(item => item.value === selectedIndustry), onChange: option => setSelectedIndustry(option.value) }) })] }) }), _jsx(FlexSpan, { children: _jsxs(Flex, { column: true, gap: "0.5rem", children: [_jsx(FlexSpan, { children: _jsx(InputLabel, { children: "Completed" }) }), _jsx(FlexSpan, { children: _jsx(Select, { width: "190px", options: completedOptions, defaultValue: completedOptions[0], value: completedOptions.find(item => item.value === selectedCompleted), onChange: option => setSelectedCompleted(option.value) }) })] }) })] }) }), _jsx(OrdersTableContainer, { children: _jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "User name" }), _jsx("th", { children: "User email" }), _jsx("th", { children: "Industry" }), _jsx("th", { children: "Address" }), _jsx("th", { children: "Coordinates" }), _jsx("th", { children: "Created" }), _jsx("th", { children: "Completed" })] }) }), _jsx("tbody", { children: filteredOrders.map(order => {
                                return (_jsxs("tr", { children: [_jsx("td", { children: order.user.name }), _jsx("td", { children: order.user.email }), _jsx("td", { children: order.user.industry.title }), _jsx("td", { children: order.address }), _jsx("td", { children: order.coordinates }), _jsx("td", { children: formatDate(order.created_at) || EMPTY_VALUE }), _jsx("td", { children: formatDate(order.completed_at) || EMPTY_VALUE })] }, `order-${order.reference_id}`));
                            }) })] }) })] }));
};
