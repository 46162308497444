import { formatLocale } from "d3";
import format from "date-fns/format";
import isNil from "lodash/isNil";
const locale = formatLocale({
    decimal: ",",
    thousands: " ",
    grouping: [3],
    currency: ["", ""],
});
export const formatNumber = (value, precision = 2) => {
    if (isNil(value)) {
        return "";
    }
    let processedPrecision = precision;
    if (value % 1 === 0) {
        processedPrecision = 0;
    }
    return locale.format(`,.${processedPrecision}f`)(value);
};
export const formatDate = (date) => {
    if (isNil(date)) {
        return "";
    }
    return format(new Date(date), "dd.MM.yyyy");
};
